import React from 'react';
import styles from './Rules.module.scss';
import Menu from './components/Photohunt/Menu.jsx';
import { Instagram } from './assets/instagram-logo.js';

function Rules() {
  return (
    <div className={styles.wrapper}>
      <Menu active="rules" />
      <div className={styles.about}>
        <div className={styles.section}>
          <h2>Hellion moto-orientēšanās brauciens “Vidzeme 2024”</h2>
          <h3>Pasākuma nolikums</h3>
          <h4>1. Vispārīgie jautājumi</h4>
          <span className={styles.bullet}>
            1.1 Dalībnieki uzņemas ievērot godīgas spēles principus. Par
            principu neievērošanu dalībnieki var tikt sodīti vai diskvalificēti.
          </span>
          <span className={styles.bullet}>
            1.2 Ikviens cilvēks, piedaloties pasākumā, piekrīt visiem pasākuma
            noteikumiem un uzņemas atbildību par savām darbībām pasākuma laikā.
          </span>
          <span className={styles.bullet}>
            1.3 Dalībnieki uzņemas ievērot CSN, satiksmes dalībnieku etiķeti un
            Latvijas valsts spēkā esošos likumus. Organizētāji nenes juridisku
            atbildību par dalībnieku LR likumu un CSN pārkapumiem.
          </span>
          <h4>2. Apraksts, laiks, vieta</h4>

          <span className={styles.bullet}>
            2.1 Moto-orientēšanās ir orientēšanās un atpūtas brauciens (turpmāk
            &quot;brauciens&quot;), kurā kā pārvietošanās līdzeklis tiek
            izmantots motocikls, ievērojot visus Ceļu satiksmes noteikumus.
          </span>
          <span className={styles.bullet}>
            2.2 Brauciena norises datums ir 2024. gada 3.augusts. Dalībnieku
            kustība notiks pa vispārējās lietošanas ceļiem, kas netiek slēgti
            publiskai satiksmei.
          </span>
          <span className={styles.bullet}>
            2.3 Brauciena starta un finiša vietas ir SIA KUSSH BREWERY, Zinātnes
            iela 11, Sigulda, Latvia, LV - 2150. Dalībnieki startē un finišē
            individuāli.
          </span>
          <span className={styles.bullet}>2.4 Brauciena norise:</span>
          <span className={styles.bullet}>2.4.1 Sestdiena, 3.augusts.</span>
          <span className={styles.bullet}>
            2.4.2 Dalībnieka reģistrācija, dalībniekam tiek nodrošināta laika
            posmā no 10:00 līdz 12:00;
          </span>
          <span className={styles.bullet}>
            2.4.3 Dalībnieka karšu iesniegšana (finiša laiks) - līdz 19:00, pēc
            šī laika rezultātus iesniegt vairs nebūs iespējams.{' '}
          </span>
          <span className={styles.bullet}>
            2.4.4 Rezultātu paziņošana- sestdien, 3. augustā, 20:00,
            apbalvošanas ceremonijā starta-finiša lokācijā.
          </span>
          <span className={styles.bullet}>
            2.4.5 Brauciena laikā dalībniekiem jāatrod kartē atzīmētie punkti
            kontrolpunkti (turpmāk “KP”) un jānofotografējas pie tiem. Pie
            vairākiem punktiem dalībniekiem ir iespēja veikt dažādus papildus
            uzdevumus, par kuru sekmīgu izpildi tiek piešķirti bonusa punkti pie
            kopvērtējuma.{' '}
          </span>
          <span className={styles.bullet}>
            2.4.6 Visi KP izvietoti Vidzemes reģionā, kā arī viens bonus-punkts
            Igaunijas teritorijā.
          </span>
          <span className={styles.bullet}>
            2.4.7 Kontrolpunktu atrašanas secība un maršruts nav noteikts.
          </span>
          <span className={styles.bullet}>
            2.4.8 Iepazīšanās ar brauciena maršrutu nav paredzēta.
          </span>
          <h4>3. Brauciena organizācija</h4>
          <span className={styles.bullet}>
            3.1 Braucienu organizē Hellion RC.
          </span>
          <span className={styles.bullet}>
            3.2 WhatsApp numurs dalībnieku jautājumiem par brauciena norisi:
            27118625;
          </span>
          <h4>4. Dalībnieki</h4>
          <span className={styles.bullet}>
            4.1 Braucienā var piedalīties jebkura fiziska persona.
          </span>
          <span className={styles.bullet}>
            4.2 Uz motocikla esošo personu skaits ierobežots ar ražotāja
            noteikto maksimālo pasažieru skaitu konkrētajam transportlīdzeklim.
          </span>
          <span className={styles.bullet}>
            4.3 Katram dalībniekam jābūt līdzi viedtālrunim (ar WhatsApp ziņu
            saņemšanas, nosūtīšanas iespējām un kura numurs jāuzrāda
            reģistrācijas formā).
          </span>
          <span className={styles.bullet}>
            4.4 Iesniedzamajām fotogrāfijām jābūt pēc iespējas kvalitatīvām,
            neizplūdušām, ar normālu gaismas un krāsu balansu.
          </span>
          <span className={styles.bullet}>
            4.5 Katrs dalībnieks pilnībā atbild par savu rīcību un veselību
            brauciena laikā, tās izraisītajām sekām un nodarītajiem zaudējumiem
            sev un trešajām personām.
          </span>
          <span className={styles.bullet}>
            4.6 Braucienā var piedalīties ar jebkuras markas un modeļa
            motociklu, kas atbilst CSN un to drīkst vadīt ar A, A1, A2
            kategorijas vadītāja apliecību. Par transportlīdzekļa tehnisko
            stāvokli brauciena laikā atbild transportlīdzekļa vadītājs.
          </span>
          <h4>5. Reģistrācija un dalības maksa</h4>
          <span className={styles.bullet}>
            5.1 Reģistrācija braucienam notiek brauciena dienā, 2024. gada 3.
            augustā no plkst 10:00-12:00, starta un finiša vietā - Zinātnes iela
            11, Sigulda, Latvia, LV - 2150
          </span>
          <span className={styles.bullet}>
            5.2 Reģistrējoties braucienam dalībnieki parakstās reģistrācijas
            tabulā, kurā ir norādīts, ka tie ir iepazinušies ar Nolikumu un
            apņemas to ievērot.
          </span>
          <span className={styles.bullet}>
            5.3 Reģistrācijas maksu nosaka Hellion RC.
          </span>
          <span className={styles.bullet}>
            5.4 Reģistrējoties dalībnieks veic skaidras naudas iemaksu 10EUR
            apmērā.
          </span>
          <span className={styles.bullet}>
            5.5 Visi reģistrētie dalībnieki saņem drukātu dalībnieka karti un
            uzdevumu lapu (turpmāk &quot;brauciena materiāli&quot;) kā arī
            pieeju digitālajiem materiāliem.
          </span>
          <span className={styles.bullet}>
            5.6 Reģistrēties un saņemt Brauciena materiālus var katra persona,
            kas dodas braucienā vai arī izmantot vienu Brauciena materiālu
            komplektu uz transportlīdzekli.
          </span>
          <span className={styles.bullet}>
            5.7 Organizatori patur tiesības neapstiprināt personas dalību
            braucienā, nepaskaidrojot iemeslus. Dalībai netiek pielaistas
            personas alkohola vai narkotisko vielu reibumā.
          </span>
          <h4>6. Moto-orientēšanās brauciena (spēles) kārtība</h4>
          <h5>6.1 Starta kārtība</h5>
          <span className={styles.bullet}>
            6.1.1 Komandas ierodas KUSSH BREWERY, Zinātnes iela 11, Sigulda,
            Latvia, LV - 2150 jebkurā sev vēlamā laikā, ievērojot individuālo
            startu darba laiku no 10:00-12:00
          </span>
          <span className={styles.bullet}>
            6.1.2 Pēc dalībnieka reģistrācijas un brauciena materiālu saņemšanas
            dalībnieki veic sava maršruta plānošanu
          </span>
          <span className={styles.bullet}>
            6.1.3 Dalībnieki uzsāk Braucienu ne vēlāk kā 12:00, braucienu drīkst
            uzsākt uzreiz pēc sekmīgas reģistrācijas.{' '}
          </span>
          <span className={styles.bullet}>
            6.1.4 Lai uzsāktu 7h laika atskaiti, dalībnieks kā pirmo bildi
            iesūta Starta/Finiša punktā esošo pulksteni.
          </span>
          <span className={styles.bullet}>
            6.1.5 Visus jautājumus par brauciena norisi vai tā noteikumiem
            dalībnieks drīkst uzdot organizatoram tikai līdz savam startam vai
            pēc finiša. Brauciena laikā dalībnieks drīkst zvanīt organizatoram
            ārkārtas situācijās (izstāšanās no brauciena, avārijas gadījumā
            utml.) vai tehnisku neskaidrību gadījumā.
          </span>
          <h5>6.2 Norise</h5>
          <span className={styles.bullet}>
            6.2.1 Dalībnieki paši izlemj savu maršrutu un KP, kurus apmeklēt. KP
            secība nav noteikta.
          </span>
          <span className={styles.bullet}>
            6.2.2 Ierodoties KP dalībniekam ir jāuzņem foto, atbilstoši Uzdevumu
            lapai, un tas UZREIZ (vadoties pēc mobilo sakaru pieejamības)
            jānosūta organizatoriem Whatsapp ziņā.
          </span>
          <span className={styles.bullet}>
            6.2.3 Ja dalībnieks iesniedz fotogrāfijas, kurās bez papildus
            darbībām (zoom/color/levels/contrast/brightness) nav iespējams
            atpazīt nepieciešamo kontrolpunktu, tas var tikt neieskaitīts.
          </span>
          <span className={styles.bullet}>
            6.2.4 Par pareizi atrastu KP tiek piešķirti punkti pēc iepriekš
            norādītajām vērtībām:
          </span>
          <div className={styles.table}>
            <table border="1" cellPadding="10">
              <tr>
                <td>Zaļais KP</td>
                <td>2p</td>
              </tr>
              <tr>
                <td>Dzeltenais KP</td>
                <td>4p</td>
              </tr>
              <tr>
                <td>Sarkanais KP</td>
                <td>7p</td>
              </tr>
              <tr>
                <td>Violetais (uzdevumu) KP </td>
                <td>5-7p</td>
              </tr>
            </table>
          </div>
          <span className={styles.bullet}>
            6.2.5 Uzdevumu lapā ir norādīti Īpašie KP, kuros ir jāizpilda
            uzdevumi.
          </span>
          <h5>6.3 Finiša kārtība</h5>
          <span className={styles.bullet}>
            6.3.1 Dalībnieki finišē, ierodoties KUSSH BREWERY, Zinātnes iela 11,
            Sigulda, Latvia, LV - 2150 jebkurā sev vēlamā laikā, ievērojot
            finiša darba laiku 17:00-19:00, bet nepārsniedzot 7h atzīmi kopš
            starta fotogrāfijas.
          </span>
          <span className={styles.bullet}>
            6.3.2 Lai reģistrētu finišu, dalībnieks kā pēdējo bildi iesūta ar
            Starta/Finiša punktā esošo pulksteni.
          </span>
          <span className={styles.bullet}>
            6.3.3 Organizatori apstiprina, ka ir saņēmuši dalībnieka KP bildes
            Whatsapp. Pēc datu saņemšanas dalībnieks tiek uzskatīts par
            finišējušu.
          </span>
          <span className={styles.bullet}>
            6.3.4 Gadījumā, ja brauciena dalībnieks kavē finiša reģistrāciju
            vairāk par 3 minūtēm pēc plānotā finiša laika, tā rezultāti tiek
            anulēti.
          </span>
          <span className={styles.bullet}>
            6.3.5 Organizatori patur tiesības piešķirt +15min finiša laikam
            objektīvu, neparedzētu iemeslu dēļ.
          </span>
          <h5>6.4 Rezultāti un apbalvošana</h5>
          <span className={styles.bullet}>
            6.4.1 Pēc KP bildes saņemšanas organizators reģistrē datus finiša
            lapā. Par katru pareizi atrastu KP dalībnieks saņem noteiktu punktu
            skaitu.
          </span>
          <span className={styles.bullet}>
            6.4.2 Pēc finiša reģistrācijas dalībniekam tiek reģistrēti iegūtie
            punkti par Īpašajiem KP (vadoties pēc atzīmēm informācijas no KP
            atbildīgajām personām)
          </span>
          <span className={styles.bullet}>
            6.4.3 Rezultātu paziņošana un apbalvošanas pasākums notiek uzreiz
            pēc visu komandu uzdevumu punktu saskaitīšanas, plkst 20:00.
          </span>
          <span className={styles.bullet}>
            6.4.4 Par uzvarētāju tiek uzskatīta tā komanda, kura ir ieguvusi
            visvairāk punktu.
          </span>
          <span className={styles.bullet}>
            6.4.5 Gadījumā, ja divi vai vairāk dalībnieki ir ieguvuši vienādu
            punktu skaitu, par uzvarētāju tiek pasludināts dalībnieks ar mazāko
            trasē pavadīto laiku. Ja laiki ir vienādi - pēc lielāko punktu
            apjoma, kas iegūti par uzdevumu izpildi.
          </span>
          <span className={styles.bullet}>
            6.4.6 Apbalvoti tiek 3 dalībnieki, kuri ieguvuši visvairāk punktu
            vai pēc nolikuma punkta 6.4.5
          </span>
          <span className={styles.bullet}>
            6.4.7 Papildus tiek apbalvoti dalībnieki šādās kategorijās:
            <ul>
              <li>3 balvas izlozes kārtībā no visiem</li>
              <li>Atraktīvākās bildes/tiesnešu favorīts</li>
              <li>Vistīrākais moto pasākuma beigās</li>
            </ul>
          </span>
          <span className={styles.bullet}>
            6.4.8 Papildus var tikt apbalvoti klātesošie dalībnieki, piedaloties
            Hellion rīkotajās atrakcijās.
          </span>
          <span className={styles.bullet}>
            6.4.9 Jebkādā strīdu gadījumā par izšķirošu tiek uzskatīts
            organizatora viedoklis.
          </span>
          <h4>7. Privātums</h4>
          <span className={styles.bullet}>
            7.1 Brauciena organizatori ciena dalībnieku privātumu.
          </span>
          <span className={styles.bullet}>
            7.2 Reģistrējoties braucienam, dalībnieks piekrīt savas personas
            datu apstrādei brauciena organizēšanas vajadzībām.
          </span>
          <span className={styles.bullet}>
            7.3 Piedaloties braucienā, dalībnieki neiebilst pret brauciena
            organizatoru rīcībā nonākušo fotogrāfiju un videomateriālu
            publiskošanu (medijos vai sociālos tīklos) vai izmantošanu
            pašreklāmas vajadzībām bez atlīdzības.
          </span>
          <h4>8. Protesti</h4>
          <span className={styles.bullet}>
            8.1 Protesti vai cita rakstura ziņojumi par brauciena gaitu vai
            rezultātiem jāiesniedz TIKAI rakstiski brauciena Organizatoram
            (club@hellion.lv) līdz oficiālo rezultātu paziņošanai.
          </span>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.section}>
          <div className={styles['footer-content']}>
            <div className={styles.instagram}>
              Sekojiet mums Instagram
              <a
                rel="noreferrer"
                href="https://www.instagram.com/hellion_rc/"
                target="_blank"
              >
                <Instagram />
              </a>
            </div>
            <div className={styles.contact}>
              Sazinieties ar mums
              <span>club@hellion.lv</span>
            </div>
            <div className={styles.copyright}>© 2023 Hellion RC</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rules;
