import React, { useState, useEffect } from 'react';
import styles from './Menu.module.scss';
import cn from 'classnames';
import logo from '../../assets/hellion-rc.webp';
import demon from '../../assets/hellion-demon.webp';

export default function Menu({
  topRef,
  aboutRef,
  eventsRef,
  merchRef,
  contactsRef,
}) {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset > 200;
    setIsScrolled(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavigate = (e, toRef) => {
    e.preventDefault();
    toRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.solid]: isScrolled,
      })}
    >
      <div
        className={cn(styles.container, {
          [styles.full]: isScrolled,
        })}
      >
        <img
          className={cn(styles['mobile-logo'])}
          src={logo}
          alt="Hellion RC"
          onClick={(e) => handleNavigate(e, topRef)}
        />
        <a
          className={styles.link}
          href="#about"
          onClick={(e) => handleNavigate(e, aboutRef)}
        >
          Klubs
        </a>
        <a
          className={styles.link}
          href="#events"
          onClick={(e) => handleNavigate(e, eventsRef)}
        >
          Pasākumi
        </a>
        <img
          className={cn(styles.logo, {
            [styles.demon]: isScrolled,
          })}
          src={isScrolled ? demon : logo}
          alt="Hellion RC"
          onClick={(e) => handleNavigate(e, topRef)}
        />
        <a
          className={styles.link}
          href="#merch"
          onClick={(e) => handleNavigate(e, merchRef)}
        >
          Merch
        </a>
        <a
          className={styles.link}
          href="#contacts"
          onClick={(e) => handleNavigate(e, contactsRef)}
        >
          Kontakti
        </a>
      </div>
    </div>
  );
}
