import React, { useState, useEffect } from 'react';
import styles from './Menu.module.scss';
import cn from 'classnames';
import logo from '../../assets/hellion-rc.webp';

export default function Menu({ active }) {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset > 200;
    setIsScrolled(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.solid]: isScrolled,
      })}
    >
      <div
        className={cn(styles.container, {
          [styles.full]: isScrolled,
        })}
      >
        <img className={cn(styles['logo'])} src={logo} alt="Hellion RC" />
        <a
          className={cn(styles.link, {
            [styles.active]: active === 'coordinates',
          })}
          href="/koordinatas"
        >
          Koordinātas
        </a>
        <a
          className={cn(styles.link, { [styles.active]: active === 'photos' })}
          href="/foto-punkti"
        >
          Punktu foto
        </a>
        <a
          className={cn(styles.link, { [styles.active]: active === 'map' })}
          href="/karte"
        >
          Punktu karte
        </a>
        <a
          className={cn(styles.link, { [styles.active]: active === 'rules' })}
          href="/noteikumi"
        >
          Noteikumi
        </a>
      </div>
    </div>
  );
}
