import React from 'react';
import styles from './Coordinates.module.scss';
import Menu from './components/Photohunt/Menu';
import { Instagram } from './assets/instagram-logo.js';
import cn from 'classnames';

const items = [
  {
    name: 'Sauriešu kontrolpunkts',
    description:
      'Speciālais uzdevums. Punktu skaits atkarīgs no uzdevuma izpildes.',
    coordinates: '56.91669, 24.36227',
    href: 'https://www.google.com/maps/dir//56.9166944,24.3622778/@56.9168678,24.3570633,16z?entry=ttu',
  },
  {
    name: 'RC Frendi',
    description:
      'Īpašais uzdevums. Punktu skaits atkarīgs no uzdevuma izpildes.',
    coordinates: '57.15393, 24.84743',
    href: 'https://www.google.com/maps/dir//57.1539347,24.8474382/@57.1537232,24.7646735,12z?entry=ttu',
  },
  {
    name: 'Valhalla Lounge',
    description:
      'Īpašais uzdevums. Punktu skaits atkarīgs no uzdevuma izpildes.',
    coordinates: '57.15628, 24.85181',
    href: 'https://www.google.com/maps/dir//57.15628,24.851812/@57.1561238,24.7695779,12z?entry=ttu',
  },
  {
    name: 'Inčukalna velnala',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.1256, 24.63848',
    href: 'https://www.google.com/maps/dir//57.1256029,24.638481/@57.1245381,24.5539205,12z?entry=ttu',
  },
  {
    name: 'Murjāņu vecā trase',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.14425, 24.68089',
    href: 'https://www.google.com/maps/dir//57.1442596,24.6808954/@57.1707111,24.6357641,12z?entry=ttu',
  },
  {
    name: 'Sējas sarkanais ķieģeļu tornis',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.19932, 24.59154',
    href: 'https://www.google.com/maps/dir//57.1993216,24.5915443/@57.2003144,24.51121,12z?entry=ttu',
  },
  {
    name: 'Bīriņu pils apsargs',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.24401, 24.66208',
    href: 'https://www.google.com/maps/dir//57.2440101,24.6620868/@57.2462301,24.5768847,12z?entry=ttu',
  },
  {
    name: 'Igates baznīca',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.37933, 24.68265',
    href: 'https://www.google.com/maps/dir//57.3793355,24.6826583/@57.3793334,24.5999862,12z?entry=ttu',
  },
  {
    name: 'Lielezera pludmale',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.50248, 24.7047',
    href: 'https://www.google.com/maps/dir//57.5024869,24.7047057/@57.5024945,24.6221193,12z?entry=ttu',
  },
  {
    name: 'Limbažu kiosks',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.51458, 24.71408',
    href: 'https://www.google.com/maps/dir//57.514588,24.7140874/@57.5143975,24.632032,12z?entry=ttu',
  },
  {
    name: 'Liepupes Muiža',
    description: 'Jānofotografē mocis kā piemēra attēlā, 2 punkti.',
    coordinates: '57.46338, 24.47102',
    href: 'https://www.google.com/maps/dir//57.4633898,24.4710225/@57.4635689,24.3888551,12z?entry=ttu',
  },
  {
    name: 'Minhauzena muzejs',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.40364, 24.42538',
    href: 'https://www.google.com/maps/dir//57.4036486,24.4253848/@57.4035607,24.3430577,12z?entry=ttu',
  },
  {
    name: 'Kalndzirnavas',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.68912, 25.25932',
    href: 'https://www.google.com/maps/dir//57.6891218,25.259327/@57.689095,25.1769272,12z?entry=ttu',
  },
  {
    name: 'Burtnieku viduslaiku pils',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.69823, 25.27787',
    href: 'https://www.google.com/maps/dir//57.6982324,25.2778758/@57.6981431,25.1954529,12z?entry=ttu',
  },
  {
    name: 'Matīšu autobusa pietura',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.69944, 25.16032',
    href: 'https://www.google.com/maps/dir//57.6994454,25.1603215/@57.6994631,25.077989,12z?entry=ttu',
  },
  {
    name: 'Balto stārķu kolonija',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.75876, 25.15763',
    href: 'https://www.google.com/maps/dir//57.7587662,25.1576354/@57.7586136,25.0752785,12z?entry=ttu',
  },
  {
    name: 'Baltās naktis - LV Z punkts',
    description: 'Jāatrod detaļa, 7 punkti.',
    coordinates: '58.07826, 25.19118',
    href: 'https://www.google.com/maps/dir//58.0782694,25.1911872/@58.0782258,25.1861182,16z?entry=ttu',
  },
  {
    name: 'Naukšēnu cilvēkmuzejs',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.88436, 25.45786',
    href: 'https://www.google.com/maps/dir//57.884361,25.457867/@57.8841418,25.3755089,12z?entry=ttu',
  },
  {
    name: 'Trošu tilts Naukšēnos',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.88687, 25.46493',
    href: 'https://www.google.com/maps/dir//57.886877,25.46493/@57.8868058,25.3825579,12z?entry=ttu',
  },
  {
    name: 'Valmiermuižas alus darītava',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.55584, 25.43171',
    href: 'https://www.google.com/maps/dir//57.55584,25.431718/@57.555851,25.3492872,12z?entry=ttu',
  },
  {
    name: 'Lielstraupes pils',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.34713, 24.94577',
    href: 'https://www.google.com/maps/dir//57.347137,24.945773/@57.347087,24.8632945,12z?entry=ttu',
  },
  {
    name: 'Melturu tilts',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.22201, 25.23571',
    href: 'https://www.google.com/maps/dir//57.2220125,25.2357154/@57.2219855,25.1533155,12z?entry=ttu',
  },
  {
    name: 'Jaunpiebalgas Svētā Toma baznīca',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.1765, 26.03588',
    href: 'https://www.google.com/maps/dir//57.1765086,26.035882/@57.1764816,25.9534821,12z?entry=ttu',
  },
  {
    name: 'Bijušais dzelzceļa tilts pār Gauju',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.16814, 26.00031',
    href: 'https://www.google.com/maps/dir//57.168145,26.0003156/@57.168118,25.9179157,12z?entry=ttu',
  },
  {
    name: 'Bijusī dzelzceļa stacija Piebalga',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.16565, 26.00846',
    href: 'https://www.google.com/maps/dir//57.1656575,26.0084692/@57.1656305,25.9260693,12z?entry=ttu',
  },
  {
    name: 'Vecpiebalgas Luterāņu baznīca',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.05782, 25.8217',
    href: 'https://www.google.com/maps/dir//57.0578227,25.8217025/@57.0577957,25.7393026,12z?entry=ttu',
  },
  {
    name: 'Vecpiebalgas ūdensrožu dīķis',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.06158, 25.81068',
    href: 'https://www.google.com/maps/dir//57.0615882,25.8106825/@57.0611012,25.7281491,12z?entry=ttu',
  },
  {
    name: 'Nēķina muiža',
    description: 'Jāatrod detaļa, 7 punkti.',
    coordinates: '57.1612, 25.66265',
    href: 'https://www.google.com/maps/dir//57.1612095,25.662659/@57.1611825,25.5802591,12z?entry=ttu',
  },
  {
    name: 'Vienkoču parks',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.19156, 25.05221',
    href: 'https://www.google.com/maps/dir//57.1915698,25.0522106/@57.1915428,24.9698107,12z?entry=ttu',
  },
  {
    name: 'Līgatnes pārceltuve pār Gauju',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.25509, 25.04424',
    href: 'https://www.google.com/maps/dir//57.2550912,25.0442412/@57.2550642,24.9618413,12z?entry=ttu',
  },
  {
    name: 'Līgatnes papīrfabrika',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.23702, 25.0416',
    href: 'https://www.google.com/maps/dir//57.237024,25.041603/@57.2371156,24.9592408,12z?entry=ttu',
  },
  {
    name: 'Cēsu dabas un kultūrvēsturiskais parks',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.31106, 25.24833',
    href: 'https://www.google.com/maps/dir//57.3110614,25.2483338/@57.3109416,25.1661894,12z?entry=ttu',
  },
  {
    name: 'Vidzeme',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.4037, 25.87039',
    href: 'https://www.google.com/maps/dir//57.4037045,25.8703951/@57.403386,25.7875495,12z?entry=ttu',
  },
  {
    name: 'Vienības laukums',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.31301, 25.27472',
    href: 'https://www.google.com/maps/dir//57.3130133,25.2747206/@57.3129864,25.1923207,12z?entry=ttu',
  },
  {
    name: 'Teperis ezers',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.42882, 25.92293',
    href: 'https://www.google.com/maps/dir//57.4288297,25.9229339/@57.4289895,25.8397244,12z?entry=ttu',
  },
  {
    name: 'Iela Smiltenē',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.4247, 25.90059',
    href: 'https://www.google.com/maps/dir//57.4247034,25.9005928/@57.4245214,25.8186778,12z?entry=ttu',
  },
  {
    name: 'Vaives dzirnavas - ūdenskritums',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.33043, 25.39489',
    href: 'https://www.google.com/maps/dir//57.3304349,25.3948949/@57.330408,25.312495,12z?entry=ttu',
  },
  {
    name: 'Smiltenes estrādes parks',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.42455, 25.89626',
    href: 'https://www.google.com/maps/dir//57.4245559,25.896265/@57.4242067,25.8140206,12z?entry=ttu',
  },
  {
    name: 'Strenču psihoneiroloģiskā slimnīca',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.62709, 25.70193',
    href: 'https://www.google.com/maps/dir//57.6270945,25.7019366/@57.6271023,25.6192267,12z?entry=ttu',
  },
  {
    name: 'Sedas kultūras nams',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.65036, 25.75405',
    href: 'https://www.google.com/maps/dir//57.6503611,25.7540543/@57.650108,25.6716287,12z?entry=ttu',
  },
  {
    name: 'Bērnu laukums - Valka',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.77634, 26.0243',
    href: 'https://www.google.com/maps/dir//57.7763451,26.024305/@57.7762653,25.9417626,12z?entry=ttu',
  },
  {
    name: 'Valkas-Valgas robežpunkts',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.77465, 26.02639',
    href: 'https://www.google.com/maps/dir//57.7746581,26.0263904/@57.7745841,25.9441513,12z?entry=ttu',
  },
  {
    name: 'Raunas brīvības piemineklis',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.3303, 25.61023',
    href: 'https://www.google.com/maps/dir//57.3303066,25.6102332/@57.3302797,25.5278332,12z?entry=ttu',
  },
  {
    name: 'Munameģis - galotne',
    description: 'Jāatrod detaļa, 7 punkti. Jāiet ar kājām!',
    coordinates: '57.71391, 27.05941',
    href: 'https://www.google.com/maps/dir//57.7139106,27.0594187/@57.7139429,26.9774588,12z?entry=ttu',
  },
  {
    name: 'Stāvvieta pie Munameģa',
    description: 'Jāatrod detaļa, 7 punkti.',
    coordinates: '57.71374, 27.05506',
    href: 'https://www.google.com/maps/dir//57.7137469,27.0550692/@57.7136557,26.9707653,12z?entry=ttu',
  },
  {
    name: 'Alūksnes ezera peldvieta',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.42661, 27.08664',
    href: 'https://www.google.com/maps/dir//57.4266195,27.0866482/@57.4264271,27.004219,12z?entry=ttu',
  },
  {
    name: 'Iela Alūksnē',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.42223, 27.05214',
    href: 'https://www.google.com/maps/dir//57.4222306,27.0521432/@57.4222912,26.9697276,12z?entry=ttu',
  },
  {
    name: 'Alūksnes sporta centrs',
    description: 'Jāatrod detaļa, 4 punkti. Labāk redzams ārpus teritorijas.',
    coordinates: '57.42303, 27.04136',
    href: 'https://www.google.com/maps/dir//57.4230316,27.0413674/@57.4229369,27.0366341,16z?entry=ttu',
  },
  {
    name: 'Velēnas Evaņģēliski luteriskā baznīca',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.23935, 26.40465',
    href: 'https://www.google.com/maps/dir//57.2393583,26.4046502/@57.2393313,26.3222503,12z?entry=ttu',
  },
  {
    name: 'Alūksnes - Gulbenes bānītis',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.41276, 27.04539',
    href: 'https://www.google.com/maps/dir//57.4127627,27.0453938/@57.4128979,27.0403302,16z?entry=ttu',
  },
  {
    name: 'Glika Ozoli un Mācītājmuiža',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.42744, 27.04065',
    href: 'https://www.google.com/maps/dir//57.4274462,27.0406597/@57.4272766,26.958322,12z?entry=ttu',
  },
  {
    name: 'Zeltiņu kodolraķešu bāze',
    description:
      'Jāatrod detaļa, 4 punkti. Mobilly prasa 3eur par iebraukšanu, bet automātiskās kameras nestrādā.',
    coordinates: '57.34696, 26.74742',
    href: 'https://www.google.com/maps/dir//57.3469666,26.7474221/@57.346882,26.6649236,12z?entry=ttu',
  },
  {
    name: 'Jaungulbenes muiža',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.05974, 26.59677',
    href: 'https://www.google.com/maps/dir//57.0597464,26.5967763/@57.0597194,26.5143765,12z?entry=ttu',
  },
  {
    name: 'Krustceles',
    description:
      'Jāatrod detaļa, 7 punkti. Šeit var nonākt tikai pa grants ceļu.',
    coordinates: '57.30865, 26.80728',
    href: 'https://www.google.com/maps/dir//57.3086559,26.8072837/@57.3086053,26.7247034,12z?entry=ttu',
  },
  {
    name: 'Stāmerienas pils un apkārtne',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.21482, 26.89951',
    href: 'https://www.google.com/maps/dir//57.2148268,26.8995151/@57.2147132,26.8167717,12z?entry=ttu',
  },
  {
    name: 'Gulbenes bibliotēka 1.punkts',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.17245, 26.76235',
    href: 'https://www.google.com/maps/dir//57.1724535,26.7623597/@57.1724357,26.6797862,12z?entry=ttu',
  },
  {
    name: 'Gulbenes bibliotēka 2.punkts',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.17234, 26.76264',
    href: 'https://www.google.com/maps/dir//57.1723411,26.762643/@57.1722519,26.680329,12z?entry=ttu',
  },
  {
    name: 'Gulbenes stacija un apkārtne',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.18179, 26.76533',
    href: 'https://www.google.com/maps/dir//57.1817957,26.7653333/@57.1816494,26.6828933,12z?entry=ttu',
  },
  {
    name: 'Gulbenes piemineklis draudzes varoņiem',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.16235, 26.75901',
    href: 'https://www.google.com/maps/dir//57.1623549,26.7590193/@57.1623705,26.6764523,12z?entry=ttu',
  },
  {
    name: 'Cesvaines pils',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '56.9695, 26.31273',
    href: 'https://www.google.com/maps/dir//56.9695068,26.3127325/@56.9695105,26.3075762,16z?entry=ttu',
  },
  {
    name: 'Gulbenes slimnīca',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.15737, 26.76136',
    href: 'https://www.google.com/maps/dir//57.1573716,26.7613606/@57.1572192,26.6791388,12z?entry=ttu',
  },
  {
    name: 'Šķieneru autobusa pietura',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '57.14023, 26.73736',
    href: 'https://www.google.com/maps/dir//57.1402384,26.7373625/@57.1402467,26.6550122,12z?entry=ttu',
  },
  {
    name: 'Biksēres avots',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '56.91883, 26.31187',
    href: 'https://www.google.com/maps/dir//56.9188384,26.3118776/@56.9187544,26.2294074,12z?entry=ttu',
  },
  {
    name: 'Lazdonas Muiža',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '56.82396, 26.24429',
    href: 'https://www.google.com/maps/dir//56.8239669,26.244296/@56.8238807,26.1619512,12z?entry=ttu',
  },
  {
    name: 'Bērzaunes evaņģēliski luteriskā baznīca',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '56.80551, 26.0306',
    href: 'https://www.google.com/maps/dir//56.8055166,26.0306074/@56.8054895,25.9482075,12z?entry=ttu',
  },
  {
    name: 'Rūdolfa Blaumaņa memoriālais muzejs',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '56.89997, 25.69672',
    href: 'https://www.google.com/maps/dir//56.8999707,25.6967292/@56.8998674,25.6142493,12z?entry=ttu',
  },
  {
    name: 'Ērgļu luterāņu baznīca',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '56.89982, 25.64876',
    href: 'https://www.google.com/maps/dir//56.8998248,25.6487615/@56.8997977,25.5663615,12z?entry=ttu',
  },
  {
    name: 'Taurupes novadpētniecības muzejs',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '56.88432, 25.35092',
    href: 'https://www.google.com/maps/dir//56.8843298,25.3509231/@56.8843027,25.2685232,12z?entry=ttu',
  },
  {
    name: 'Taurupes pamatskola',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '56.88553, 25.34943',
    href: 'https://www.google.com/maps/dir//56.8855318,25.3494308/@56.8855047,25.2670309,12z?entry=ttu',
  },
  {
    name: 'Dubkalnu karjers',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '56.84175, 24.54654',
    href: 'https://www.google.com/maps/dir//56.8417515,24.5465422/@56.836204,24.4727663,12z?entry=ttu',
  },
  {
    name: 'Mototrase Elkšņi',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '56.82232, 24.53253',
    href: 'https://www.google.com/maps/dir//56.822323,24.5325317/@56.8198617,24.4246951,12z?entry=ttu',
  },
  {
    name: 'Ogresgala vanšu tilts',
    description: 'Jāatrod detaļa, 4 punkti.',
    coordinates: '56.80162, 24.75412',
    href: 'https://www.google.com/maps/dir//56.8016296,24.7541266/@56.8011958,24.6717312,12z?entry=ttu',
  },
  {
    name: 'Suntažu estrāde',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '56.90624, 24.92879',
    href: 'https://www.google.com/maps/dir//56.9062441,24.9287996/@56.9042374,24.8475395,12z?entry=ttu',
  },
  {
    name: 'Mālpils kultūras centrs',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '57.01023, 24.95752',
    href: 'https://www.google.com/maps/dir//57.0102326,24.9575291/@57.0102693,24.8749585,12z?entry=ttu',
  },
  {
    name: 'Zaube - scorpions',
    description: 'Jāatrod detaļa, 2 punkti.',
    coordinates: '56.99664, 25.2609',
    href: 'https://www.google.com/maps/dir//56.9966468,25.2609034/@56.9966197,25.1785035,12z?entry=ttu',
  },
];

function Coordinates() {
  return (
    <div className={styles.wrapper}>
      <Menu active={'coordinates'} />
      <div className={styles.about}>
        <div className={styles.section}>
          <h2>Moto-orientēšanās punktu koordinātas</h2>
          <h3>Spied uz punkta, lai atvērtu to kartē</h3>
          <a
            href="https://www.google.com/maps/dir//57.1474328,24.8927256/@57.1473466,24.8102098,12z?entry=ttu"
            target="_blank"
            rel="noreferrer"
          >
            <div className={cn(styles.coordinate, styles.finish)}>
              <span className={styles.name}>Kussh</span>
              <span className={styles.description}>Starts un finišs</span>
              Atvērt karti (57.14743, 24.89272)
            </div>
          </a>
          <div className={styles['coordinates-wrapper']}>
            {items.map((item, index) => {
              return (
                <a
                  key={index}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={styles.coordinate}>
                    <span className={styles.name}>{item.name}</span>
                    <span className={styles.description}>
                      {item.description}
                    </span>
                    Atvērt karti ({item.coordinates})
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.section}>
          <div className={styles['footer-content']}>
            <div className={styles.instagram}>
              Sekojiet mums Instagram
              <a
                rel="noreferrer"
                href="https://www.instagram.com/hellion_rc/"
                target="_blank"
              >
                <Instagram />
              </a>
            </div>
            <div className={styles.contact}>
              Sazinieties ar mums
              <span>club@hellion.lv</span>
            </div>
            <div className={styles.copyright}>© 2023 Hellion RC</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coordinates;
