import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import Coordinates from './Coordinates';
import Huntphoto from './Huntphoto';
import Rules from './Rules';
import Map from './Map';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/orientesanas" element={<Coordinates />} />
      <Route path="/kooridnatas" element={<Coordinates />} />
      <Route path="/koordinatas" element={<Coordinates />} />
      <Route path="/foto-punkti" element={<Huntphoto />} />
      <Route path="/karte" element={<Map />} />
      <Route path="/noteikumi" element={<Rules />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

function NoMatch() {
  return (
    <div style={{ color: '#fff' }}>
      <h1>404</h1>
      <h2>Šeit nekā nav</h2>
      <p>
        <Link to="/">Iet uz Hellion sākuma lapu</Link>
      </p>
    </div>
  );
}

export default App;
