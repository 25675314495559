import React, { useState } from 'react';
import styles from './Huntphoto.module.scss';
import Menu from './components/Photohunt/Menu.jsx';
import { Instagram } from './assets/instagram-logo.js';

import { RowsPhotoAlbum } from 'react-photo-album';
import 'react-photo-album/rows.css';

import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

function assetLink(asset) {
  return `https://hellion.lv/static/photohunt/${asset}`;
}

const breakpoints = [3840, 1920, 1080, 640, 384, 256, 128];

const slides = [
  { asset: 'aaa.jpeg', width: 750, height: 500 },
  { asset: 'aab.jpeg', width: 1562, height: 1153 },
  { asset: 'aac.jpg', width: 1152, height: 2048 },
  { asset: 'aad.jpg', width: 1506, height: 2048 },
  { asset: 'aae.jpeg', width: 1200, height: 1253 },
  { asset: 'aaf.jpeg', width: 839, height: 755 },
  { asset: 'aag.jpg', width: 1773, height: 1152 },
  { asset: 'aah.jpg', width: 2048, height: 1701 },
  { asset: 'aai.jpg', width: 1153, height: 1153 },
  { asset: 'aaj.jpg', width: 901, height: 1600 },
  { asset: 'aak.jpg', width: 690, height: 590 },
  { asset: 'aal.jpg', width: 704, height: 1015 },
  { asset: 'aam.jpg', width: 1153, height: 2048 },
  { asset: 'aan.jpeg', width: 901, height: 1600 },
  { asset: 'aao.jpg', width: 1150, height: 1191 },
  { asset: 'aap.jpeg', width: 607, height: 597 },
  { asset: 'aar.jpg', width: 1536, height: 2048 },
  { asset: 'aas.jpg', width: 1366, height: 2048 },
  { asset: 'aat.jpg', width: 901, height: 916 },
  { asset: 'aau.jpg', width: 2048, height: 1536 },
  { asset: 'aav.jpg', width: 2048, height: 1536 },
  { asset: 'aaz.jpg', width: 579, height: 657 },
  { asset: 'aba.jpg', width: 901, height: 1121 },
  { asset: 'abb.jpg', width: 1287, height: 1291 },
  { asset: 'abc.jpeg', width: 1153, height: 1145 },
  { asset: 'abd.jpeg', width: 1600, height: 901 },
  { asset: 'abe.jpg', width: 1536, height: 2048 },
  { asset: 'abf.jpeg', width: 859, height: 792 },
  { asset: 'abg.jpg', width: 616, height: 787 },
  { asset: 'abh.jpg', width: 901, height: 1043 },
  { asset: 'abi.jpg', width: 114, height: 173 },
  { asset: 'abj.jpg', width: 1536, height: 2048 },
  { asset: 'abk.jpeg', width: 901, height: 1600 },
  { asset: 'abl.jpg', width: 1585, height: 641 },
  { asset: 'abm.jpg', width: 779, height: 1143 },
  { asset: 'abn.jpeg', width: 1600, height: 2040 },
  { asset: 'abo.jpg', width: 1530, height: 901 },
  { asset: 'abp.jpg', width: 389, height: 861 },
  { asset: 'abr.jpg', width: 2048, height: 996 },
  { asset: 'abs.jpg', width: 437, height: 426 },
  { asset: 'abt.jpeg', width: 1206, height: 302 },
  { asset: 'abu.jpg', width: 1333, height: 1535 },
  { asset: 'abv.jpeg', width: 901, height: 1600 },
  { asset: 'abz.jpg', width: 962, height: 230 },
  { asset: 'aca.jpeg', width: 762, height: 910 },
  { asset: 'acb.jpg', width: 1330, height: 1153 },
  { asset: 'acc.jpg', width: 1198, height: 336 },
  { asset: 'acd.jpg', width: 1188, height: 1197 },
  { asset: 'ace.jpeg', width: 1538, height: 1152 },
  { asset: 'acf.jpg', width: 1153, height: 1031 },
  { asset: 'acg.jpeg', width: 301, height: 535 },
  { asset: 'ach.jpeg', width: 1153, height: 1412 },
  { asset: 'aci.jpg', width: 2048, height: 1536 },
  { asset: 'acj.jpg', width: 1506, height: 2008 },
  { asset: 'ack.jpg', width: 2048, height: 1007 },
  { asset: 'acl.jpg', width: 804, height: 509 },
  { asset: 'acm.jpg', width: 856, height: 958 },
  { asset: 'acn.jpeg', width: 1153, height: 1171 },
  { asset: 'aco.jpeg', width: 723, height: 1059 },
  { asset: 'acp.jpeg', width: 1536, height: 1505 },
  { asset: 'acr.jpg', width: 2048, height: 1536 },
  { asset: 'acs.jpeg', width: 1079, height: 746 },
  { asset: 'act.jpeg', width: 1180, height: 1038 },
  { asset: 'acu.jpeg', width: 407, height: 561 },
  { asset: 'acv.jpg', width: 1536, height: 1474 },
  { asset: 'acz.jpg', width: 1536, height: 2048 },
  { asset: 'ada.jpeg', width: 792, height: 639 },
  { asset: 'adb.jpg', width: 779, height: 762 },
  { asset: 'adc.jpeg', width: 1153, height: 1315 },
  { asset: 'add.jpg', width: 901, height: 1600 },
  { asset: 'ade.jpg', width: 2048, height: 1536 },
  { asset: 'adf.jpg', width: 1187, height: 1464 },
  { asset: 'adg.jpeg', width: 901, height: 1142 },
].map(({ asset, width, height }) => ({
  src: assetLink(asset, width),
  width,
  height,
  srcSet: breakpoints.map((breakpoint) => ({
    src: assetLink(asset, breakpoint),
    width: breakpoint,
    height: Math.round((height / width) * breakpoint),
  })),
}));

function Huntphoto() {
  const [index, setIndex] = useState(-1);

  return (
    <div className={styles.wrapper}>
      <Menu active={'photos'} />
      <div className={styles.about}>
        <div className={styles.section}>
          <div className={styles.content}>
            <RowsPhotoAlbum
              photos={slides}
              targetRowHeight={150}
              onClick={({ index: current }) => setIndex(current)}
            />

            <Lightbox
              index={index}
              slides={slides}
              open={index >= 0}
              close={() => setIndex(-1)}
              plugins={[Fullscreen, Thumbnails, Zoom]}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.section}>
          <div className={styles['footer-content']}>
            <div className={styles.instagram}>
              Sekojiet mums Instagram
              <a
                rel="noreferrer"
                href="https://www.instagram.com/hellion_rc/"
                target="_blank"
              >
                <Instagram />
              </a>
            </div>
            <div className={styles.contact}>
              Sazinieties ar mums
              <span>club@hellion.lv</span>
            </div>
            <div className={styles.copyright}>© 2023 Hellion RC</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Huntphoto;
