import React, { useRef } from 'react';
import { Menu } from './components';
import styles from './Home.module.scss';
import hellionPresident from './assets/hellion-prezidents.webp';
import photohunt from './assets/photohunt.webp';
import hotsauce from './assets/hellion-original-hot-sauce.webp';
import sauceBackground from './assets/hot-sauce-background.webp';
import sfrt from './assets/sfrt.webp';
import kussh from './assets/kussh.webp';
import siguldaAdventures from './assets/sigulda-adventures.webp';
import shoothouse from './assets/shoothouse.webp';
import coffee from './assets/coffee.webp';
import svilis from './assets/svilis.webp';
import sunbears from './assets/sunbears.webp';
import Zilver from './assets/Zilver.webp';
import { Instagram } from './assets/instagram-logo.js';
import { Parallax } from 'react-parallax';

function Home() {
  const topRef = useRef(null);
  const aboutRef = useRef(null);
  const eventsRef = useRef(null);
  const merchRef = useRef(null);
  const contactsRef = useRef(null);

  return (
    <div className={styles.wrapper}>
      <Menu
        topRef={topRef}
        aboutRef={aboutRef}
        eventsRef={eventsRef}
        merchRef={merchRef}
        contactsRef={contactsRef}
      />
      <div ref={topRef} className={styles.banner} />
      <div className={styles.about}>
        <div className={styles.section}>
          <div ref={aboutRef} className={styles['about-text']}>
            <p>
              Hellion ir draugu pulks, kas pēc neskaitāmiem nobrauktiem
              kilometriem kopā saprata, ka vajag savu vārdu, savas krāsas, savu
              klubu. Nākam no dažādām pilsētām un novadiem. Jutāmies gana jauni
              un enerģiski, lai rakstītu savu stāstu, un MCA mūsu ieceri
              atbalstīja, līdz ar to 2022. gada 1. aprīlī tika dibināts Hellion
              RC.
            </p>
            <p>
              Hellion ir nemiera gars, kas mājo ikvienā. Mūsu kluba krāsas rotā
              dēmons, kas simbolizē šo nemieru pēc ceļa, nemieru pēc
              piedzīvojumiem un sajūtām, kuras var sniegt vienīgi motocikls un
              vējš matos. Lai arī kolektīvi dodam priekšroku čoperiem un
              krūzeriem, kā arī melnām ādas vestēm un dūmotai viskija glāzei pēc
              gara ceļa, tomēr katrā no mums ir savs dēmons. Tā ir viena no mūsu
              kluba lielākajām bagātībām – neraugoties uz kilometrāžu vai
              dažādajām iekšējo dēmonu īpatnībām, esam spējīgi vienoties kopīgam
              mērķim – rakstīt savu motostāstu.
            </p>
            <p>Tas viss ir tikai ellīgi labs sākums.</p>

            <p>
              Kluba prezidents
              <br />
              <i>Rūdolfs</i>
            </p>
          </div>
          <img
            className={styles.president}
            src={hellionPresident}
            alt="Hellion PR president"
          />
        </div>
      </div>
      <div ref={eventsRef} className={styles.events}>
        <div className={styles.section}>
          <div className={styles['events-wrapper']}>
            <div className={styles['events-container']}>
              <div className={styles['event-image']}>
                <img src={photohunt} alt="Hellion Photo Hunt 2024" />
              </div>
              <div className={styles['event-schedule']}>
                <span className={styles['event-schedule-time']}>
                  10:00 - 12:00
                </span>
                <span>
                  Dalībnieka kartes saņemšana KUSSH
                  <br />
                  (Zinātnes iela 11, Sigulda)
                </span>
                <span className={styles['event-schedule-time']}>19:00</span>
                <span>Dalībnieka kartes iesniegšana KUSSH</span>
                <span className={styles['event-schedule-time']}>20:00</span>
                <span>Apbalvošana</span>
                <span className={styles['event-schedule-time']}>
                  21:00 - 01:00
                </span>
                <span>Tusiņš</span>
                <span className={styles['event-link']}>
                  <a href="/hellion-foto-orientesanas-2024.pdf" target="_blank">
                    VAIRĀK INFO
                  </a>
                  <a href="/orientesanas" target="_blank">
                    DALĪBNIEKIEM
                  </a>
                  <a href="/noteikumi" target="_blank">
                    NOTEIKUMI
                  </a>
                </span>
              </div>
            </div>
            <h2>Atbalsta</h2>
            <div className={styles['sponsors-container']}>
              <div className={styles['sponsor']}>
                <img style={{ width: '100%' }} src={sfrt} alt="SFRT" />
              </div>
              <div className={styles['sponsor']}>
                <img src={kussh} alt="Kussh" />
              </div>
              <div className={styles['sponsor']}>
                <img src={siguldaAdventures} alt="Sigulda Adventures" />
              </div>
              <div className={styles['sponsor']}>
                <img src={shoothouse} alt="Shoothouse" />
              </div>
              <div className={styles['sponsor']}>
                <img src={coffee} alt="Royal Coffee" />
              </div>
              <div className={styles['sponsor']}>
                <img src={svilis} alt="Svilis Pepper Farm" />
              </div>
              <div className={styles['sponsor']}>
                <img src={sunbears} alt="Sunbears" />
              </div>
              <div className={styles['sponsor']}>
                <img src={Zilver} alt="Zilver" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={merchRef} className={styles.section}>
        <Parallax bgImage={sauceBackground} strength={-400}>
          <div className={styles['hot-sauce-section']}>
            <img
              className={styles.sauce}
              src={hotsauce}
              alt="Hellion Original Hot Sauce"
            />
            <span className={styles['hot-text']}>
              Sadarbojoties ar Svilis Pepper Farm Hellion RC ir realizējis
              unikālu, Latvijā līdz šim neredzētu produktu! Hellion Original Mix
              ir asā mērcīte, kas ir bāzēta uz augstvērtīga ruma bāzes
              apvienojot trīs piparu maisījumu, kas neatstās vienaldzīgu nevienu
              aso garšu cienītāju. Ja vēlies to nogaršot un mazliet pamodināt
              sevī dēmonus, raksti mums vai ieskaties{' '}
              <a
                href="http://www.svilispepperfarm.com"
                rel="noreferrer"
                target="_blank"
              >
                svilispepperfarm.com
              </a>
            </span>
          </div>
        </Parallax>
      </div>
      <div className={styles.calendar}>
        <div className={styles.section}>
          <div className={styles['calendar-container']}>
            <h2 className={styles['calendar-title']}>
              HELLION 2024 MOTO KALENDĀRS
            </h2>
            <p className={styles['calendar-text']}>
              Laiks skrien nemanot, it īpaši, kad esi izbraucienā vai darbojies
              ap savu motociklu garāžā. Tieši tāpēc kopā ar fotogrāfu Reini
              Inkēnu esam radījuši kalendāru 2024. gadam, uz kuru nav iespējams
              nepaskatīties: mūsu moto parku izdaiļo 12 neatvairāmas latviešu
              meitenes, kas neatstās vienaldzīgu nevienu baikeri.
              <br />
              <br />
              Pasūti kalendāru savai garāžai, kluba mājai vai darba galdam jau
              tagad! Skaits ierobežots.
              <br />
              <br />
              Sazinies ar mums club@hellion.lv vai arī Instagram @hellion_rc
            </p>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div ref={contactsRef} className={styles.section}>
          <div className={styles['footer-content']}>
            <div className={styles.instagram}>
              Sekojiet mums Instagram
              <a
                rel="noreferrer"
                href="https://www.instagram.com/hellion_rc/"
                target="_blank"
              >
                <Instagram />
              </a>
            </div>
            <div className={styles.contact}>
              Sazinieties ar mums
              <span>club@hellion.lv</span>
            </div>
            <div className={styles.copyright}>© 2023 Hellion RC</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
